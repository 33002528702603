import Image from "next/image";
import { useTranslation } from '@/components/useTranslation';


const style = {
  wrapper: `mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8`,
  wrapperInner: `mx-auto px-4 sm:px-0 sm:text-center`,
  grid: `mt-8 grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3`,
  item: `block rounded-xl p-8 transition`,
  text: `mt-3 !text-base`

}

const Services = () => {
  const t = useTranslation();
  
  const servicesData = [
    {
      icon: "/images/global/services/1.png",
      title: t.services_item1_title,
      description:
        t.services_item1_description,
    },
    {
      icon: "/images/global/services/2.png",
      title: t.services_item2_title,
      description:
        t.services_item2_description,
    },
    {
      icon: "/images/global/services/3.png",
      title: t.services_item3_title,
      description:
        t.services_item3_description,
    },
    {
      icon: "/images/global/services/4.png",
      title: t.services_item4_title,
      description:
        t.services_item4_description,
    },
    {
      icon: "/images/global/services/5.png",
      title: t.services_item5_title,
      description:
        t.services_item5_description,
    },
    {
      icon: "/images/global/services/6.png",
      title: t.services_item6_title,
      description:
        t.services_item6_description,
    },
  ];

  return (
    <>
      <section className="services" id="ablauf">
        <div className={style.wrapper}>
          <div className={style.wrapperInner}>
            <h2>
              {t.services_title}
            </h2>
          </div>

          <div className={style.grid}>
            {servicesData.map((service, index) => {
              return (
                <div key={index} className={style.item}>
                  <Image
                    src={service.icon}
                    alt="Services Icon"
                    width={45}
                    height={45}
                  />
                  <h3> {service.title} </h3>
                  <p className={style.text}>
                    {service.description}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default Services;
