import Image from "next/image";
import { useTranslation } from '../../useTranslation';

const style = {
    wrapper: `mx-auto max-w-7xl px-8 py-12 lg:py-16`,
    grid: `grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-8 place-content-center items-center`,
    image: `relative overflow-hidden lg:order-last`,
    item: `lg:py-24 w-full mx-auto max-w-md`,

}

const Content3 = () => {
    
    const t = useTranslation();

    return (
        <section className='verifizierte-bewertungen-lokaler-reisefuhrer'>
            <div className={style.wrapper}>
                <div className={style.grid}>
                    <div className={style.image}>
                        <video autoPlay loop muted playsInline>
                            <source src="/video/comments.mp4" type="video/mp4" /> 
                        </video>
                        <Image src="/images/subpage/google/hero-comment.png" alt="Only Comments" width={650} height={650} />

                    </div>
                    <div className={style.item}>
                        <h2>{t.home_step3_title}</h2>
                        <div className='mt-6' dangerouslySetInnerHTML={{__html: t.home_step3_description}}></div>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default Content3;

